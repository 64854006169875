import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('teaser/link-bar');

const list = moduleScope.querySelector('ul.-collapsible');
const toggle = moduleScope.querySelector('.content-platform__link-bar__view-more');

toggle?.addEventListener('click', (e) => {
    e.preventDefault();

    list.classList.toggle('-open');
    toggle.classList.toggle('-rotated');
});
